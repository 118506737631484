import { DateTime } from 'luxon';

export const validateExp = (exp) => {
  const iat = Math.round(DateTime.utc().toMillis() / 1000);
  return exp > iat;
};

export const validateObjectExp = ({ exp = 0 } = {}) => {
  if (exp === 0) {
    return false;
  }
  const iat = Math.round(DateTime.utc().toMillis() / 1000);
  return exp > iat;
};
