import buildDataLayer from 'data-layer';

import {
  setPath,
  setPasses,
  addPass,
  updatePass,
  deletePass,
  setDetailedPasses,
  setOncionuser,
  updateOncionuser,
  setCompanyUsers,
  setSubscription,
  setSubscriptionPrices,
  setLoyaltyCard,
  setActivePasses,
  setMessages,
  setFour,
  resetStore,
} from 'state/store';

export default (logger) => (state, { type, payload }) => {
  const dataLayer = buildDataLayer();
  const onUpdateState = () => {
    dataLayer.trackState({ descriptor: type });
    logger.info({ message: 'reducer:action', type });
  };

  switch (type) {
    case 'set-path-at-auth': {
      const { path } = payload;
      setPath({ value: path, onUpdateState });
      break;
    }
    case 'set-passes': {
      const { passes } = payload;
      setPasses({ value: passes, onUpdateState });
      break;
    }
    case 'remove-passes': {
      setPasses({ value: [], onUpdateState });
      break;
    }
    case 'add-pass': {
      const { pass } = payload;
      addPass({ value: pass, onUpdateState });
      break;
    }
    case 'update-pass': {
      const { pass } = payload;
      updatePass({ value: pass, onUpdateState });
      break;
    }
    case 'enable-pass': {
      const { passId } = payload;
      updatePass({ value: { passId, state: 'active' }, onUpdateState });
      break;
    }
    case 'delete-pass': {
      const { passId } = payload;
      deletePass({ value: passId, onUpdateState });
      break;
    }
    case 'set-detailed-pass': {
      const { detailedPass } = payload;
      setDetailedPasses({ value: detailedPass, onUpdateState });
      break;
    }
    case 'remove-detailed-pass': {
      setDetailedPasses({ value: {}, onUpdateState });
      break;
    }
    case 'set-oncion-user': {
      const { oncionuser } = payload;
      const { userId = '-1' } = oncionuser;
      dataLayer.setUserId({ userId });
      setOncionuser({ value: oncionuser, onUpdateState });
      break;
    }
    case 'update-oncion-user-token': {
      const { tokendata } = payload;
      updateOncionuser({ value: tokendata, onUpdateState });
      break;
    }
    case 'set-company-users': {
      const { companyUsers } = payload;
      setCompanyUsers({ value: companyUsers, onUpdateState });
      break;
    }
    case 'set-subscription': {
      const { subscription } = payload;
      setSubscription({ value: subscription, onUpdateState });
      break;
    }
    case 'remove-subscription': {
      setSubscription({ value: null, onUpdateState });
      break;
    }
    case 'set-subscription-prices': {
      const { subscriptionPrices } = payload;
      setSubscriptionPrices({ value: subscriptionPrices, onUpdateState });
      break;
    }
    case 'set-loyalty-card': {
      const { loyaltyCard } = payload;
      setLoyaltyCard({ value: loyaltyCard, onUpdateState });
      break;
    }
    case 'remove-loyalty-card': {
      setLoyaltyCard({ value: null, onUpdateState });
      break;
    }
    case 'set-active-passes': {
      const { activePasses } = payload;
      setActivePasses({ value: activePasses, onUpdateState });
      break;
    }
    case 'set-messages': {
      const { messages } = payload;
      setMessages({ value: messages, onUpdateState });
      break;
    }
    case '404-on': {
      setFour({ value: true, onUpdateState });
      break;
    }
    case '404-off': {
      const { path } = payload;
      setFour({ value: false, onUpdateState });
      setPath({ value: path, onUpdateState });
      break;
    }
    case 'logged-out': {
      resetStore();
      break;
    }
    default:
      break;
  }
};
