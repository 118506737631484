import React from 'react';
import loadable from '@loadable/component';
import root from 'window-or-global';
import { Auth0Provider } from '@auth0/auth0-react';
import { configLocalStorage } from 'state/store';

import {
  RECAPTCHA_URL, GA_PLACES_URL,
  features,
} from 'gatsby-env-variables';
import {
  CONTACT_US, HOME_ROUTE,
  CREATE_ROUTE, CREATE_MEDIA_ROUTE,
  NON_APP_ROUTES,
  SUBSCRIPTION_ROUTES,
  LANDING_PAGE, LOGIN_ROUTE,
  REGISTRATION_START, TOO_MANY_FREE_ROUTE,
  LOGOUT,
} from 'constants/navigation';

import { InitLoadProvider } from 'contexts/init-load-context';
import { NavigationProvider } from 'contexts/navigation-context';
import { NotificationProvider } from 'contexts/notification-context';
import { DataLoadProvider } from 'contexts/data-load-context';
import { StateProvider } from 'contexts/state-context';
import { SeoProvider } from 'contexts/seo-context';

import { isAuthenticated } from 'services/authentication-service';

import { getErrorBoundary } from 'apputil/bugsnag';
import { actionOnScript, loadScript } from 'apputil/script-loaders';
import { showCookieBanner } from 'apputil/cookies';
import { removeUser } from 'apputil/user';
import { AUTH_ZERO_CONFIG } from 'apputil/auth-0';

import 'typeface-roboto';
import {} from './src/styles/layout/_base.scss';

const AppWrapper = loadable(() => import('components/app-wrapper'));
const NoAuthBrowserWrapper = loadable(() => import('components/browser-wrapper/no-auth'));
const BrowserWrapper = loadable(() => import('components/browser-wrapper'));
const CookieConsent = loadable(() => import('components/cookie-consent'));
const Header = loadable(() => import('components/header'));
const LandingPageNavigation = loadable(() => import('components/navigation/landing-page'));
const LoginPageNavigation = loadable(() => import('components/navigation/login'));
const RegsiterPageNavigation = loadable(() => import('components/navigation/register'));
const LandingPageHeader = loadable(() => import('components/header/landing-header'));
const LogInWrapper = loadable(() => import('components/login-wrapper'));
const Navigation = loadable(() => import('components/navigation'));
const PissTakeBanner = loadable(() => import('components/piss-take'));

export const onServiceWorkerUpdateReady = () => {
/*  const authenticated = isAuthenticated();
  if (authenticated) {
    removeUser();
  } */
  setTimeout(() => root.location.reload(true));
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const { pathname = HOME_ROUTE } = location;

  if (pathname === CONTACT_US) {
    actionOnScript({
      src: RECAPTCHA_URL, key: 'recaptcha-script', action: loadScript,
    });
  }

  if (pathname === CREATE_ROUTE || pathname === CREATE_MEDIA_ROUTE) {
    const { addLocation } = features;
    const { google = {} } = root;
    const { maps } = google;
    if (addLocation && !maps) {
      loadScript({ src: GA_PLACES_URL, key: 'maps-script' });
    }
  }
};

export const wrapPageElement = ({ element, props }) => {
  const { uri, location, pageResources = {} } = props;
  const authenticated = isAuthenticated();

  if (uri === LANDING_PAGE) {
    return (
      <>
        <LandingPageHeader />
        <NoAuthBrowserWrapper>
        <span id="top-anchor" />
        <LandingPageNavigation />
          <AppWrapper>
            {element}
            {showCookieBanner() && <CookieConsent wideScreen/>}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </>
    );
  }
  if (uri === LOGIN_ROUTE) {
    return (
      <>
        <LandingPageHeader />
        <NoAuthBrowserWrapper>
          <LoginPageNavigation />
          <AppWrapper>
            {element}
            {showCookieBanner() && <CookieConsent wideScreen/>}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </>
    );
  }
  if (uri === REGISTRATION_START) {
    return (
      <>
        <LandingPageHeader />
        <NoAuthBrowserWrapper>
          <RegsiterPageNavigation />
          <AppWrapper>
            {element}
            {showCookieBanner() && <CookieConsent wideScreen/>}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </>
    );
  }
  if (uri === LOGOUT) {
    return (
      <SeoProvider>
      <StateProvider>
      <NavigationProvider>
        <Header uri={uri} location={location}/>
        <NoAuthBrowserWrapper>
          <RegsiterPageNavigation />
          <AppWrapper>
            {element}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </NavigationProvider>
      </StateProvider>
      </SeoProvider>
    );
  }
  if (uri === CONTACT_US) {
    return (
      <SeoProvider>
      <NavigationProvider>
      <DataLoadProvider>
        <Header uri={uri} location={location}/>
        <NoAuthBrowserWrapper>
          {authenticated ? <Navigation /> : <LandingPageNavigation />}
          <AppWrapper>
            {authenticated && <PissTakeBanner />}
            {element}
            {showCookieBanner() && <CookieConsent wideScreen={!authenticated}/>}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </DataLoadProvider>
      </NavigationProvider>
      </SeoProvider>
    );
  }

  if (SUBSCRIPTION_ROUTES.includes(uri)) {
    return (
    <SeoProvider>
    <StateProvider>
    <NavigationProvider>
    <NotificationProvider>
    <DataLoadProvider>
      <LogInWrapper>
        <Header uri={uri} location={location}/>
        <BrowserWrapper>
          <Navigation />
          <AppWrapper>
            {element}
            {showCookieBanner() && <CookieConsent />}
          </AppWrapper>
        </BrowserWrapper>
      </LogInWrapper>
    </DataLoadProvider>
    </NotificationProvider>
    </NavigationProvider>
    </StateProvider>
    </SeoProvider>
    );
  }

  if (NON_APP_ROUTES.includes(uri)) {
    return (
      <SeoProvider>
      <NavigationProvider>
        <Header uri={uri} location={location}/>
        <NoAuthBrowserWrapper>
        <span id="top-anchor" />
          {authenticated ? <Navigation /> : <LandingPageNavigation />}
          <AppWrapper>
            {authenticated && <PissTakeBanner />}
            {element}
            {showCookieBanner() && <CookieConsent />}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </NavigationProvider>
      </SeoProvider>
    );
  }

  if (uri === TOO_MANY_FREE_ROUTE) {
    return (
      <SeoProvider>
      <StateProvider>
      <NavigationProvider>
        <Header uri={uri} location={location}/>
        <NoAuthBrowserWrapper>
          <LandingPageNavigation wideScreen/>
          <AppWrapper>
            {element}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </NavigationProvider>
      </StateProvider>
      </SeoProvider>
    );
  }

  const { page = {} } = pageResources;
  if (page.path && page.path === '/404.html') {
    return (
      <SeoProvider>
      <StateProvider>
      <NavigationProvider>
        <Header uri={uri} location={location}/>
        <NoAuthBrowserWrapper>
          <LandingPageNavigation wideScreen/>
          <AppWrapper>
            {element}
          </AppWrapper>
        </NoAuthBrowserWrapper>
      </NavigationProvider>
      </StateProvider>
      </SeoProvider>
    );
  }

  return (
    <SeoProvider>
    <StateProvider>
    <NavigationProvider>
    <NotificationProvider>
    <DataLoadProvider>
      <LogInWrapper>
        <Header uri={uri} location={location}/>
        <BrowserWrapper>
          {authenticated ? <Navigation /> : <LandingPageNavigation />}
          <AppWrapper>
            <PissTakeBanner />
            {element}
            {showCookieBanner() && <CookieConsent />}
          </AppWrapper>
        </BrowserWrapper>
      </LogInWrapper>
    </DataLoadProvider>
    </NotificationProvider>
    </NavigationProvider>
    </StateProvider>
    </SeoProvider>
  );
};

const ErrorBoundary = getErrorBoundary();

export const wrapRootElement = ({ element }) => {
  configLocalStorage();

  return (
    <Auth0Provider {...AUTH_ZERO_CONFIG}>
      <InitLoadProvider>
        <ErrorBoundary>
          {element}
        </ErrorBoundary>
      </InitLoadProvider>
    </Auth0Provider>
  );
};
