import { Cookies } from 'react-cookie-consent';

export const showCookieBanner = () => {
  const consentCookie = Cookies.get('CookieConsent');
  const legacyConsentCoookie = Cookies.get('CookieConsent-legacy');
  const mobileSafariConsentCoookie = Cookies.get('CookieConsent-mobile-safari');
  if (typeof consentCookie === 'string' || consentCookie instanceof String) {
    return false;
  }

  if (typeof legacyConsentCoookie === 'string' || legacyConsentCoookie instanceof String) {
    return false;
  }

  if (typeof mobileSafariConsentCoookie === 'string' || mobileSafariConsentCoookie instanceof String) {
    return false;
  }

  return true;
};
