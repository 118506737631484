import root from 'window-or-global';
import { DEV_MODE, LOG } from 'gatsby-env-variables';

export default () => {
  const canTrack = (data) => {
    if (!DEV_MODE && root.dataLayer && root.fbq) {
      return typeof data === 'object' && data !== null;
    }
    return false;
  };

  const trackEvent = ({
    category, /* the common grouping name (eg> 'api_success') */
    descriptor, /* readable descriptor */
    label, /* optional extra string */
    value, /* optional int value */
    fbData = null,
  }) => {
    if (canTrack({ descriptor, category })) {
      root.gtag('event', descriptor, {
        event_category: category, // the common grouping name (eg> 'api_success')
        event_label: label, // optional extra string
        value, // optional int value
      });
      const baseData = { action: descriptor, label };
      if (fbData) {
        root.fbq('track', category, { ...baseData, ...fbData });
      } else {
        root.fbq('track', category, { ...baseData });
      }
    }
  };

  return {
    setUserId: ({ userId }) => {
      if (root.dataLayer) {
        if (LOG) {
          console.log(JSON.stringify({
            level: 'INFO',
            message: 'userid-sent-ga',
          }));
        }
        root.gtag('set', { user_id: userId });
      }
    },
    trackState: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'state', descriptor, label, value, fbData,
      });
    },
    trackUser: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'user', descriptor, label, value, fbData,
      });
    },
    trackNewUser: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'new-user', descriptor, label, value, fbData,
      });
    },
    trackPass: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'pass', descriptor, label, value, fbData,
      });
    },
    trackWindowShopper: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'window-shopper', descriptor, label, value, fbData,
      });
    },
    trackError: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'api_error', descriptor, label, value, fbData,
      });
    },
    trackApiSuccess: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'api_success', descriptor, label, value, fbData,
      });
    },
    trackApiError: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'api_error', descriptor, label, value, fbData,
      });
    },
    trackContactUs: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'contact_us', descriptor, label, value, fbData,
      });
    },
    trackGoogleMaps: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'google_maps', descriptor, label, value, fbData,
      });
    },
    trackStripe: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'track_stripe', descriptor, label, value, fbData,
      });
    },
    trackLanding: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'track_landing_page', descriptor, label, value, fbData,
      });
    },
    trackNavigation: ({
      descriptor, /* readable descriptor */
      label, /* optional extra string */
      value, /* optional int value */
      fbData, /* optional object value */
    }) => {
      trackEvent({
        category: 'track_navigation', descriptor, label, value, fbData,
      });
    },
  };
};
