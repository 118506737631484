import { HOME_LOGGED_IN_ROUTE } from 'constants/navigation';

export const initialState = {
  oncionuser: {},
  companyUsers: null,
  detailedPass: {},
  passes: [],
  path: HOME_LOGGED_IN_ROUTE,
  loyaltyCard: {},
  subscriptionPrices: null,
  subscription: null,
  activePasses: 0,
  messages: [],
  four: false,
};
