import Logger from './Logger';
import payloadBuilder from './build-payload';
import {
  INFO, WARN, DEBUG, ERROR, EXIT, TEST,
} from './constants';

export default () => {
  const logger = new Logger();

  return {
    info: (logData = {}) => {
      const payload = payloadBuilder(logData);
      logger.log(INFO, payload.log);
    },

    warn: (logData = {}) => {
      const payload = payloadBuilder(logData);
      logger.log(WARN, payload.log);
    },

    debug: (logData = {}) => {
      const payload = payloadBuilder(logData);
      logger.log(DEBUG, payload.log);
    },

    error: (logData = {}) => {
      const payload = payloadBuilder(logData);
      logger.log(ERROR, payload.error);
    },

    exit: (logData = {}) => {
      const payload = payloadBuilder(logData);
      logger.log(EXIT, payload.log);
    },

    test: (logData = {}) => {
      const payload = payloadBuilder(logData);
      logger.log(TEST, payload.log);
    },
  };
};
