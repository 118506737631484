export const HOME_NAME = 'home';
export const SUBSCRIPTION_NAME = 'subscription';
export const ADD_NAME = 'add';
export const LOYALTY_NAME = 'loyalty-cards';
export const ACCOUNT_NAME = 'account';
export const CONTACT_NAME = 'contact';

export const SUPPORT_HOME = '/support';
export const FAQ = '/support/faq';
export const SUPPORT_PROBLEM = '/support/report-problem';

export const SUPPORT_DOCS = '/support/documents';
export const TERMS_CONDITONS = '/support/documents/terms-and-conditions';
export const PRIVACY_POLICY = '/support/documents/privacy-policy';
export const COOKIE_POLICY = '/support/documents/cookie-policy';
export const RAISE_BUG_GH = 'https://github.com/Total-Loyalty/support/issues';

export const LANDING_PAGE = '/';
export const HOME_ROUTE = '/';
export const HOME_LOGGED_IN_ROUTE = '/home';
export const ABOUT_US = '/about-us';
export const CONTACT_US = '/contact-us';
export const OUR_VALUES = '/our-values';

export const ALL_CARDS_ROUTE = '/loyalty-cards';
export const CREATE_ROUTE = '/loyalty-card/create';
export const CREATE_MEDIA_ROUTE = '/loyalty-card/create-media';
export const UPDATE_ROUTE = '/loyalty-card/update';
export const UPDATE_MEDIA_ROUTE = '/loyalty-card/update-media';
export const DELETE_ROUTE = '/loyalty-card/delete';

export const LOGIN_ROUTE = '/login';
export const LOGIN_CB = '/cb';
export const TOO_MANY_FREE_ROUTE = '/too-many-free';
export const REGISTRATION_START = '/registration';
export const REGISTRATION_CB = '/registration/cb';
export const SUBSCRIPTION_ROUTE = '/registration/subscription';
export const SUBSCRIPTION_ROUTE_SLASH = '/registration/subscription/';
export const STARTUP_SUBSCRIPTION = '/registration/subscription/start-up';
export const STARTUP_SUBSCRIPTION_SLASH = '/registration/subscription/start-up/';
export const BRONZE_SUBSCRIPTION = '/registration/subscription/bronze';
export const BRONZE_SUBSCRIPTION_SLASH = '/registration/subscription/bronze/';
export const SILVER_SUBSCRIPTION = '/registration/subscription/silver';
export const SILVER_SUBSCRIPTION_SLASH = '/registration/subscription/silver/';
export const GOLD_SUBSCRIPTION = '/registration/subscription/gold';
export const GOLD_SUBSCRIPTION_SLASH = '/registration/subscription/gold/';

export const ACCOUNT_ROUTE = '/account';
export const LOGOUT = '/logout';

export const CARD_ROUTE = '/loyalty-card';

export const LOYALTY_SHARE_ROUTE = '/loyalty-card/share';
export const LOYALTY_SHARE_IOS_ROUTE = '/loyalty-card/share/ios';
export const LOYALTY_SHARE_ANDROID_ROUTE = '/loyalty-card/share/android';

export const LOYALTY_ROUTE = '/loyalty-card/loyalty';
export const LOYALTY_QR_ROUTE = '/loyalty-card/loyalty/qr-code';
export const LOYALTY_SEARCH_ROUTE = '/loyalty-card/loyalty/search';
export const LOYALTY_UPDATE_ROUTE = '/loyalty-card/loyalty/update';
export const LOYALTY_STAMP_ROUTE = '/loyalty-card/loyalty/update/stamp';
export const LOYALTY_REDEEM_ROUTE = '/loyalty-card/loyalty/update/redeem';

export const MESSAGE_ROUTE = '/loyalty-card/message';
export const ANALYTICS_ROUTE = '/loyalty-card/analytics';

export const ACTIVATE_ROUTE = '/loyalty-card/activate';

export const SUBSCRIPTION_ROUTES = [
  SUBSCRIPTION_ROUTE,
  STARTUP_SUBSCRIPTION,
  BRONZE_SUBSCRIPTION,
  SILVER_SUBSCRIPTION,
  GOLD_SUBSCRIPTION,
  SUBSCRIPTION_ROUTE_SLASH,
  STARTUP_SUBSCRIPTION_SLASH,
  BRONZE_SUBSCRIPTION_SLASH,
  SILVER_SUBSCRIPTION_SLASH,
  GOLD_SUBSCRIPTION_SLASH,
];

export const NON_APP_ROUTES = [
  SUPPORT_HOME,
  TERMS_CONDITONS,
  PRIVACY_POLICY,
  COOKIE_POLICY,
  LOGIN_ROUTE,
  REGISTRATION_START,
];
