import React, { createContext, useContext, useState } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';

export const SeoContext = createContext();

export const useSeoContext = () => useContext(SeoContext) || {};

const initalState = {
  title: 'Home',
  description: 'Digital loyalty made easy for everyone',
  meta: [],
  lang: 'en',
  noside: false,
};

export const SeoProvider = ({ children }) => {
  const [helmet, setHelmetProps] = useState(initalState);

  return (
          <SeoContext.Provider
            value={{
              setHelmet: (helmetProps) => setHelmetProps({
                ...initalState,
                ...helmetProps,
              }),
              get helmetProps() {
                return { ...helmet };
              },
              get initialHelmetProps() {
                return initalState;
              },
            }}>
            {children}
          </SeoContext.Provider>
  );
};

SeoProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
