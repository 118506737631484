import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BUGSNAG_SITE_KEY } from 'gatsby-env-variables';

export const getErrorBoundary = () => {
  Bugsnag.start({
    apiKey: BUGSNAG_SITE_KEY,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.CONTEXT || process.env.NODE_ENV,
    appVersion: process.env.DEPLOY_ID,
  });
  return Bugsnag.getPlugin('react').createErrorBoundary(React);
};
