// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-cb-js": () => import("./../../../src/pages/cb.js" /* webpackChunkName: "component---src-pages-cb-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-logout-index-js": () => import("./../../../src/pages/logout/index.js" /* webpackChunkName: "component---src-pages-logout-index-js" */),
  "component---src-pages-loyalty-card-activate-js": () => import("./../../../src/pages/loyalty-card/activate.js" /* webpackChunkName: "component---src-pages-loyalty-card-activate-js" */),
  "component---src-pages-loyalty-card-analytics-js": () => import("./../../../src/pages/loyalty-card/analytics.js" /* webpackChunkName: "component---src-pages-loyalty-card-analytics-js" */),
  "component---src-pages-loyalty-card-create-js": () => import("./../../../src/pages/loyalty-card/create.js" /* webpackChunkName: "component---src-pages-loyalty-card-create-js" */),
  "component---src-pages-loyalty-card-create-media-js": () => import("./../../../src/pages/loyalty-card/create-media.js" /* webpackChunkName: "component---src-pages-loyalty-card-create-media-js" */),
  "component---src-pages-loyalty-card-delete-js": () => import("./../../../src/pages/loyalty-card/delete.js" /* webpackChunkName: "component---src-pages-loyalty-card-delete-js" */),
  "component---src-pages-loyalty-card-index-js": () => import("./../../../src/pages/loyalty-card/index.js" /* webpackChunkName: "component---src-pages-loyalty-card-index-js" */),
  "component---src-pages-loyalty-card-loyalty-index-js": () => import("./../../../src/pages/loyalty-card/loyalty/index.js" /* webpackChunkName: "component---src-pages-loyalty-card-loyalty-index-js" */),
  "component---src-pages-loyalty-card-loyalty-qr-code-js": () => import("./../../../src/pages/loyalty-card/loyalty/qr-code.js" /* webpackChunkName: "component---src-pages-loyalty-card-loyalty-qr-code-js" */),
  "component---src-pages-loyalty-card-loyalty-search-js": () => import("./../../../src/pages/loyalty-card/loyalty/search.js" /* webpackChunkName: "component---src-pages-loyalty-card-loyalty-search-js" */),
  "component---src-pages-loyalty-card-loyalty-update-index-js": () => import("./../../../src/pages/loyalty-card/loyalty/update/index.js" /* webpackChunkName: "component---src-pages-loyalty-card-loyalty-update-index-js" */),
  "component---src-pages-loyalty-card-loyalty-update-redeem-js": () => import("./../../../src/pages/loyalty-card/loyalty/update/redeem.js" /* webpackChunkName: "component---src-pages-loyalty-card-loyalty-update-redeem-js" */),
  "component---src-pages-loyalty-card-loyalty-update-stamp-js": () => import("./../../../src/pages/loyalty-card/loyalty/update/stamp.js" /* webpackChunkName: "component---src-pages-loyalty-card-loyalty-update-stamp-js" */),
  "component---src-pages-loyalty-card-message-js": () => import("./../../../src/pages/loyalty-card/message.js" /* webpackChunkName: "component---src-pages-loyalty-card-message-js" */),
  "component---src-pages-loyalty-card-share-android-js": () => import("./../../../src/pages/loyalty-card/share/android.js" /* webpackChunkName: "component---src-pages-loyalty-card-share-android-js" */),
  "component---src-pages-loyalty-card-share-index-js": () => import("./../../../src/pages/loyalty-card/share/index.js" /* webpackChunkName: "component---src-pages-loyalty-card-share-index-js" */),
  "component---src-pages-loyalty-card-share-ios-js": () => import("./../../../src/pages/loyalty-card/share/ios.js" /* webpackChunkName: "component---src-pages-loyalty-card-share-ios-js" */),
  "component---src-pages-loyalty-card-update-js": () => import("./../../../src/pages/loyalty-card/update.js" /* webpackChunkName: "component---src-pages-loyalty-card-update-js" */),
  "component---src-pages-loyalty-card-update-media-js": () => import("./../../../src/pages/loyalty-card/update-media.js" /* webpackChunkName: "component---src-pages-loyalty-card-update-media-js" */),
  "component---src-pages-loyalty-cards-index-js": () => import("./../../../src/pages/loyalty-cards/index.js" /* webpackChunkName: "component---src-pages-loyalty-cards-index-js" */),
  "component---src-pages-our-values-index-js": () => import("./../../../src/pages/our-values/index.js" /* webpackChunkName: "component---src-pages-our-values-index-js" */),
  "component---src-pages-registration-cb-js": () => import("./../../../src/pages/registration/cb.js" /* webpackChunkName: "component---src-pages-registration-cb-js" */),
  "component---src-pages-registration-index-js": () => import("./../../../src/pages/registration/index.js" /* webpackChunkName: "component---src-pages-registration-index-js" */),
  "component---src-pages-registration-subscription-bronze-js": () => import("./../../../src/pages/registration/subscription/bronze.js" /* webpackChunkName: "component---src-pages-registration-subscription-bronze-js" */),
  "component---src-pages-registration-subscription-gold-js": () => import("./../../../src/pages/registration/subscription/gold.js" /* webpackChunkName: "component---src-pages-registration-subscription-gold-js" */),
  "component---src-pages-registration-subscription-index-js": () => import("./../../../src/pages/registration/subscription/index.js" /* webpackChunkName: "component---src-pages-registration-subscription-index-js" */),
  "component---src-pages-registration-subscription-silver-js": () => import("./../../../src/pages/registration/subscription/silver.js" /* webpackChunkName: "component---src-pages-registration-subscription-silver-js" */),
  "component---src-pages-registration-subscription-start-up-js": () => import("./../../../src/pages/registration/subscription/start-up.js" /* webpackChunkName: "component---src-pages-registration-subscription-start-up-js" */),
  "component---src-pages-support-documents-cookie-policy-js": () => import("./../../../src/pages/support/documents/cookie-policy.js" /* webpackChunkName: "component---src-pages-support-documents-cookie-policy-js" */),
  "component---src-pages-support-documents-index-js": () => import("./../../../src/pages/support/documents/index.js" /* webpackChunkName: "component---src-pages-support-documents-index-js" */),
  "component---src-pages-support-documents-privacy-policy-js": () => import("./../../../src/pages/support/documents/privacy-policy.js" /* webpackChunkName: "component---src-pages-support-documents-privacy-policy-js" */),
  "component---src-pages-support-documents-terms-and-conditions-js": () => import("./../../../src/pages/support/documents/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-support-documents-terms-and-conditions-js" */),
  "component---src-pages-support-faq-js": () => import("./../../../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-report-problem-js": () => import("./../../../src/pages/support/report-problem.js" /* webpackChunkName: "component---src-pages-support-report-problem-js" */),
  "component---src-pages-too-many-free-index-js": () => import("./../../../src/pages/too-many-free/index.js" /* webpackChunkName: "component---src-pages-too-many-free-index-js" */)
}

