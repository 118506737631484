export default (log) => ({

  get log() {
    return { ...log };
  },

  get error() {
    const {
      stack = '',
      errorData,
      statusCode,
      data,
      message,
      trace,
    } = log;

    return {
      statusCode,
      data,
      message,
      errorData,
      stackTrace: Array.isArray(trace) && trace.length > 0 ? trace : stack,
    };
  },
});
