import localforage from 'localforage';
import { initialState } from 'state/initial-state';

export const PATH = 'PATH';
export const PASSES = 'PASSES';
export const DETAILED_PASS = 'DETAILED_PASS';
export const ONCIONUSER = 'ONCIONUSER';
export const COMPANYUSERS = 'COMPANYUSERS';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const SUBSCRIPTION_PRICES = 'SUBSCRIPTION_PRICES';
export const LOYALTY_CARD = 'LOYALTY_CARD';
export const ACTIVE_PASSES = 'ACTIVE_PASSES';
export const MESSAGES = 'MESSAGES';
export const FOUR = 'FOUR';

const setStoreItem = (
  { type, value, onUpdateState },
) => localforage.setItem(type, value).then(onUpdateState);

export const configLocalStorage = () => {
  localforage.config({
    driver: [
      localforage.WEBSQL,
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
    ],
    name: 'store',
  });
};

export const setPath = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: PATH, value, onUpdateState });

export const setPasses = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: PASSES, value, onUpdateState });

export const addPass = (
  { value, onUpdateState = () => {} },
) => localforage.getItem(PASSES).then(
  (passes) => {
    const exists = passes.some((p) => p.passId === value.passId);
    return setStoreItem({
      type: PASSES,
      value: exists ? passes : [value, ...passes],
      onUpdateState,
    });
  },
);

export const updatePass = (
  { value, onUpdateState = () => {} },
) => localforage.getItem(PASSES).then(
  (passes) => {
    const updatedPasses = passes.map((p) => {
      if (p.passId === value.passId) {
        return {
          ...p,
          ...value,
        };
      }
      return p;
    });
    return setStoreItem({
      type: PASSES,
      value: updatedPasses,
      onUpdateState,
    });
  },
);

export const deletePass = (
  { value, onUpdateState = () => {} },
) => localforage.getItem(PASSES).then(
  (passes) => {
    const updatedPasses = passes.filter((p) => p.passId !== value);
    return setStoreItem({
      type: PASSES,
      value: updatedPasses,
      onUpdateState,
    });
  },
);

export const setDetailedPasses = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: DETAILED_PASS, value, onUpdateState });

export const setCompanyUsers = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: COMPANYUSERS, value, onUpdateState });

export const setOncionuser = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: ONCIONUSER, value, onUpdateState });

export const updateOncionuser = (
  { value, onUpdateState = () => {} },
) => localforage.getItem(ONCIONUSER).then(
  (oncionuser) => {
    if (oncionuser) {
      return setStoreItem({
        type: DETAILED_PASS,
        value: { ...oncionuser, ...value },
        onUpdateState,
      });
    }
    return setStoreItem({ type: DETAILED_PASS, value, onUpdateState });
  },
);

export const setSubscription = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: SUBSCRIPTION, value, onUpdateState });

export const setSubscriptionPrices = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: SUBSCRIPTION_PRICES, value, onUpdateState });

export const setLoyaltyCard = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: LOYALTY_CARD, value, onUpdateState });

export const setActivePasses = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: ACTIVE_PASSES, value, onUpdateState });

export const setMessages = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: MESSAGES, value, onUpdateState });

export const setFour = (
  { value, onUpdateState = () => {} },
) => setStoreItem({ type: FOUR, value, onUpdateState });

export const resetStore = () => {
  const {
    oncionuser,
    companyUsers,
    detailedPass,
    passes,
    path,
    loyaltyCard,
    subscriptionPrices,
    subscription,
    activePasses,
    messages,
    four,
  } = initialState;
  setPath({
    value: path,
    onUpdateState: setPasses({
      value: passes,
      onUpdateState: setDetailedPasses({
        value: detailedPass,
        onUpdateState: setOncionuser({
          value: oncionuser,
          onUpdateState: setCompanyUsers({
            value: companyUsers,
            onUpdateState: setSubscription({
              value: subscription,
              onUpdateState: setSubscriptionPrices({
                value: subscriptionPrices,
                onUpdateState: setLoyaltyCard({
                  value: loyaltyCard,
                  onUpdateState: setActivePasses({
                    value: activePasses,
                    onUpdateState: setMessages({
                      value: messages,
                      onUpdateState: setFour({ value: four }),
                    }),
                  }),
                }),
              }),
            }),
          }),
        }),
      }),
    }),
  });
};
