import root from 'window-or-global';

export const actionOnScript = (props) => {
  const { key, action } = props;
  const elements = document.querySelectorAll(`[data-key="${key}"]`);
  if (!Array.isArray(elements) || elements.length === 0) {
    action(props);
  }
};

export const loadScript = ({ src, async = false, key }) => {
  const script = root.document.createElement('script');
  script.src = src;
  script.async = async;
  script.defer = true;
  script.setAttribute('data-key', key);
  root.document.body.append(script);
};

export const loadFunction = ({ inner, key }) => {
  const script = root.document.createElement('script');
  script.innerHTML = inner;
  script.defer = true;
  script.setAttribute('data-key', key);
  root.document.body.append(script);
};
