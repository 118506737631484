import { navigate } from 'gatsby';
import root from 'window-or-global';
import { BASE_APPLICATION } from 'gatsby-env-variables';

export default (route) => {
  if (typeof window !== 'undefined') {
    navigate(route);
  }

  if (root.___navigate) {
    root.___navigate(route);
  }

  if (root.location && root.location.replace) {
    root.location.replace(`${BASE_APPLICATION}${route}`);
  }
};
