import { LOG } from 'gatsby-env-variables';

import { EXIT, ERROR, TEST } from './constants';

export default class Logger {
  static state = {};

  log = (level, log) => {
    if (LOG) {
      if (level === EXIT) {
        console.log(JSON.stringify({ level, ...log }));
      } else {
        console.log(JSON.stringify({ level, ...log }));
      }
    }
    if (!LOG) {
      if (level === ERROR || level === TEST) {
        import('@bugsnag/js').then((bugsnag) => {
          bugsnag.notify(level, (event) => {
            event.addMetadata(level, log);
          });
        });
      }
    }
  }
}
