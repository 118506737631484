import {
  BASE_APPLICATION,
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  AUTH_AUDIENCE,
} from 'gatsby-env-variables';

import { LOGIN_CB, REGISTRATION_CB, LOGOUT } from 'constants/navigation';

export const LOG_OUT_CONFIG = {
  returnTo: `${BASE_APPLICATION}/`,
  clientID: AUTH_CLIENT_ID,
};

export const LOGIN_REDIRECT_URL = `${BASE_APPLICATION}${LOGIN_CB}`;
export const SIGNUP_REDIRECT_URL = `${BASE_APPLICATION}${REGISTRATION_CB}`;
export const LOGOUT_REDIRECT_URL = `${BASE_APPLICATION}${LOGOUT}`;

export const AUTH_ZERO_CONFIG = {
  domain: AUTH_DOMAIN,
  clientId: AUTH_CLIENT_ID,
  scope: 'read:current_user',
  redirectUri: LOGIN_REDIRECT_URL,
  audience: AUTH_AUDIENCE,
};
