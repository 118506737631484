import React, {
  useState, createContext, useMemo, useContext,
} from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import loadable from '@loadable/component';

export const NotificationContext = createContext();

export const useNoticationContext = () => useContext(NotificationContext) || {};

const Notification = loadable(() => import('components/notification'));

const defaultProps = {
  open: false,
  message: 'hi, hope you\'re having a great day',
  type: 'info',
  duration: 5000,
};

export const NotificationProvider = ({ children }) => {
  const [snackProps, setSnackProps] = useState(defaultProps);

  const MemoNotification = useMemo(() => {
    const {
      duration, open, message, type,
    } = snackProps;
    return (
      <Notification
        open={open}
        message={message}
        variant={type}
        duration={duration}
        onClose={() => {
          setSnackProps({
            ...snackProps,
            open: false,
          });
        }}
        />
    );
  }, [snackProps]);

  return (
      <NotificationContext.Provider
        value={{
          MemoNotification,
          showNotification: ({ message, type, duration = 5000 }) => setSnackProps({
            ...snackProps,
            open: true,
            message,
            type,
            duration,
          }),
          types: {
            info: 'info',
            success: 'success',
            error: 'error',
            softerror: 'softerror',
            warning: 'warning',
            critical: 'critical', // for messages (add to notification)
          },
        }}>
        {children}
      </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
