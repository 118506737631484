import React, {
  useState, createContext, useMemo, useContext,
} from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import root from 'window-or-global';

import { useAuth0 } from '@auth0/auth0-react';

import { getOncionuser } from 'state/selectors';
import navigate from 'apputil/safe-navigate';
import { getUserEmail } from 'apputil/user';
import { validateObjectExp } from 'apputil/validate-exp';

import {
  SUBSCRIPTION_ROUTE,
  HOME_LOGGED_IN_ROUTE,
  LANDING_PAGE,
  CREATE_ROUTE,
  ACCOUNT_ROUTE,
} from 'constants/navigation';

import { useStateContext } from 'contexts/state-context';
import { isAuthenticated as authServIsAuthenticated } from 'services/authentication-service';

const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext) || {};

export const awaitNavigate = (route) => new Promise(
  (resolve) => setTimeout(() => {
    navigate(route);
    resolve();
  }, 100),
);

export const NavigationProvider = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { dispatch } = useStateContext();
  const { loginWithRedirect } = useAuth0();

  const props = useMemo(() => ({
    passSidebar: { collapsed: sidebarCollapsed },
  }), [sidebarCollapsed]);

  return (
      <NavigationContext.Provider
        value={{
          ...props,
          navigate,
          togglePassSidebar: () => setSidebarCollapsed(!sidebarCollapsed),

          notSubscribed: async () => {
            await awaitNavigate(SUBSCRIPTION_ROUTE);
          },
          gotoAccount: async () => {
            await awaitNavigate(ACCOUNT_ROUTE);
          },
          homePageOnly: async () => {
            const authenticated = authServIsAuthenticated();
            const oncionuser = await getOncionuser();
            if (!authenticated && !validateObjectExp(oncionuser)) {
              return awaitNavigate(LANDING_PAGE);
            }

            if (!authenticated) {
              const { location = {} } = root || {};
              const { pathname = HOME_LOGGED_IN_ROUTE, search = '' } = location;
              if (pathname.indexOf('cb') === -1) {
                const path = pathname === HOME_LOGGED_IN_ROUTE ? pathname : `${pathname}${search}`;
                dispatch({ type: 'set-path-at-auth', payload: { path } });
              }
              const email = getUserEmail() || '';
              if (emailRegex.test(email)) {
                return loginWithRedirect({ login_hint: email });
              }
              return loginWithRedirect({ });
            }
            return awaitNavigate(HOME_LOGGED_IN_ROUTE);
          },
          landingPageOnly: async () => {
            await awaitNavigate(LANDING_PAGE);
          },
          loggedInHome: async () => {
            await awaitNavigate(HOME_LOGGED_IN_ROUTE);
          },
          noPassToEdit: async () => {
            await awaitNavigate(CREATE_ROUTE);
          },
          four: () => dispatch({ type: '404-on' }),
        }}>
        {children}
      </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
