import localforage from 'localforage';
import {
  PATH,
  PASSES,
  DETAILED_PASS,
  ONCIONUSER,
  COMPANYUSERS,
  SUBSCRIPTION,
  SUBSCRIPTION_PRICES,
  LOYALTY_CARD,
  MESSAGES,
  FOUR,
} from 'state/store';
import { isAuthenticated } from 'services/authentication-service';

export const getPathAtAuth = async () => localforage.getItem(PATH);

export const getHeldPasses = async () => localforage.getItem(PASSES);

export const getPassesById = async (passId) => {
  const passes = await getHeldPasses();
  return passes.find((p) => p.passId === passId) || {};
};

export const getDetailedPass = async () => localforage.getItem(DETAILED_PASS);

export const hasCompanyUsers = async () => {
  const companyUsers = await localforage.getItem(COMPANYUSERS);
  return companyUsers !== null;
};

export const getOncionuser = async () => localforage.getItem(ONCIONUSER);

export const gaLoggedInLabel = () => {
  const isLoggedIn = isAuthenticated();
  return isLoggedIn ? 'logged-in' : 'logged-out';
};

export const getHeldSubscription = async () => localforage.getItem(SUBSCRIPTION);

export const getSubscriptionInvoiceId = async () => {
  const subscription = await getHeldSubscription();
  if (subscription === null) {
    return '';
  }
  const { invoiceId = '' } = subscription;
  return invoiceId;
};

export const getLoyaltyCard = async () => localforage.getItem(LOYALTY_CARD);

export const getHeldPrices = async () => localforage.getItem(SUBSCRIPTION_PRICES);

export const hasHeldPrices = async () => {
  const subscriptionPrices = await getHeldPrices();
  return subscriptionPrices !== null;
};

export const getMessages = async () => localforage.getItem(MESSAGES);

export const getFour = async () => localforage.getItem(FOUR);
