import ls from 'local-storage';
import { resetStore } from 'state/store';

import { USER, USER_EMAIL } from 'gatsby-env-variables';

export const getUser = () => {
  const user = ls.get(USER);
  return user;
};

export const setUser = (user) => {
  ls.set(USER, user);
};

export const removeUserLight = () => {
  ls.remove(USER);
};

export const removeUser = (force = false) => {
  removeUserLight();

  if (force) {
    resetStore();
  }
};

export const setUserEmail = (email) => {
  ls.set(USER_EMAIL, email);
};

export const getUserEmail = () => {
  const email = ls.get(USER_EMAIL) || '';
  return email;
};
