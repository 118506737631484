export default class LoggableError extends Error {
  constructor({
    statusCode, data, message, trace,
  }) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LoggableError);
    }
    this.statusCode = statusCode;
    this.name = 'LoggableError';
    this.data = data;
    this.message = message;
    this.trace = trace;
  }

  get errorData() {
    return JSON.stringify({
      message: this.message,
      data: this.data,
      statusCode: this.statusCode,
    });
  }
}
