import React, {
  createContext, useMemo, useContext, useReducer,
} from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import { buildLogger } from 'logger';

import getReducer from 'state/reducer';
import { initialState } from 'state/initial-state';

export const StateContext = createContext();

export const useStateContext = () => useContext(StateContext) || {};

export const StateProvider = ({ children }) => {
  const logger = buildLogger();
  const reducer = getReducer(logger);
  const [state, dispatch] = useReducer(reducer, initialState);

  const props = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
      <StateContext.Provider
        value={{
          ...props,
          getToken: async () => {
            const { getOncionuser } = await import('state/selectors');
            const oncionuser = await getOncionuser();
            const { tokenActionProvider } = await import('apputil/token');
            const tokenBuilder = tokenActionProvider(logger, dispatch);
            const data = await tokenBuilder(oncionuser);
            return data;
          },
        }}>
        {children}
      </StateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
